import React from "react";
import "../../../../../src/assets/css/privacy_policy.scss";
const Privacy_policy = ()=>{
    window.scrollTo(0, 0);
    return(
        <>
        <div className="privacy">
            
    <section className="privacy_sec">
        <div className="container">
            <h3 className="text-center">Privacy Policy</h3>
            <div className="row">
                <div className="col-12">
                    <div className="left_date_time">
                        <h2>Last Modified: 1 July 2023</h2>
                    </div>
                </div>
            </div>
            <div>
                <h4><strong>1. Introduction</strong></h4>
                <p> Premiumtax.Credit is dedicated to protecting your personal information and informing
                    you about how we use your information. This privacy policy applies to your use of the Premiumtax.Credit website
                    and all other related services (collectively the “Site”). This Privacy Policy should be read in
                    conjunction with the Terms of Use and is integrated into the Terms of Use. All capitalized proper
                    nouns not defined in this Agreement will have the same definitions and meanings as defined by the
                    Terms of Use. Please review this Privacy Policy periodically as we may revise it from time to time.
                    If you do not agree with or accept our Privacy Policy in its entirety, you must not access or use
                    the Site. If you use the Site following a change to the terms of this Privacy Policy you agree to
                    accept the revised policies.</p>
                <h4><strong>2. Information Collected</strong></h4>
                <p>At Premiumtax.Credit, we collect personally identifiable information (“PII”) and non-personally identifiable
                    information (“Non-PII”) from you. Personally identifiable information is information that can be
                    used to identify you personally. Non-personally identifiable information is information that must be
                    combined with other information to identify you personally.</p>
                <p className="line">Personally Identifiable Information Collected</p>
                <p>Personal information is information that identifies you as an individual, such as your name, mailing
                    address, e-mail address, telephone number, and fax number. We may collect personal information from
                    you in a variety of ways:</p>
                <ul>
                    <li>When you send us an application or other form</li>
                    <li>When you conduct a transaction with us, our affiliates, or others</li>
                    <li>When we collect information about in you in support of a transaction, such as credit card
                        information</li>
                    <li> In some places on this Site you have the opportunity to send us personal information about
                        yourself, to elect to receive particular information, to purchase access to one of our products
                        or services, or to participate in an activity.</li>
                </ul>
                <p className="line">Non-Identifying Information</p>
                <p>Non personal information is information that cannot identify you. Whenever you use our website, we
                    may collect Non-PII from you, such as your IP address, zip code, gender, browsing history, search
                    history, and registration history, interactions with the Site, usage information, location,
                    referring URL, browser, operating system, data usage, data transferred, and Internet service
                    provider. We may also collect information including but not limited to postings you make on the
                    public areas of our website, messages you send to us, and correspondence we receive from other
                    members or third parties about your activities or postings.</p>
                <p><b>Use of Your Information</b></p>
                <p>Some of your information will be visible to us or other users of the Site to facilitate
                    communication. We use various tracking mechanisms to track the use of customers on our Site. We will
                    never sell your information without your permission; however you agree that we may use your
                    information in the following ways:</p>
                <ul>
                    <li> To provide any services offered and to operate the Premiumtax.Credit Site.</li>
                    <li>To enhance or improve our users’ experiences.</li>
                    <li>To contact you via email or other electronic communications where you have an inquiry.</li>
                    <li> To notify you of additional Premiumtax.Credit updates.</li>
                    <li>To share your information with third party partners or third parties hired by us to perform
                        functions, market additional products and services, and provide services to us subject to the
                        obligations consistent with this Privacy Policy and on the condition that the third parties use
                        your information only on our behalf and pursuant to our instructions. </li>
                </ul>
                <p>Personal information is used to primarily contact you to discuss qualifications and offers concerning
                    health insurance and/or programs like Medicare, and to provide a detailed quote if you qualify, or
                    any other matter related to marketing health insurance products. Methods we, or a third-party
                    affiliate, may use to contact you are via: phone call, e-mail, telephone auto-dialing system, or
                    SMS/MMS messaging, by Premiumtax.Credit or one of its affiliate(s) or marketing partners. You may opt out of
                    receiving communications at any time by contacting Premiumtax.Credit or any of its marketing partners or
                    affiliate(s).</p>
                <p>Personal information collected online may be combined with information you provide to us through
                    other sources We may also remove your personal identifiers (your name, email address, etc.). In this
                    case, you would no longer be identified as a single unique individual. Once we have de-identified
                    information, it is non-personal information and we may treat it like other non-personal information.
                    Finally, we may use your personal information to protect our rights or property, or to protect
                    someone’s health, safety or welfare, and to comply with a law or regulation, court order or other
                    legal process.</p>
                <h4><strong>3. Accessing, Editing, and Removing Your Information</strong></h4>
                <p>If you have any questions or wish to review, change, or access any of your information collected by
                    us, please contact us at <a href="">info@premiumtax.credit</a> Additionally, if you wish to opt
                    out of our data collection practices or would like us to remove any of your information from our
                    databases, please either follow instructions on the page of the web site on which you have provided
                    such information or subscribed or registered or contact us. After you have canceled your account
                    please be aware that we may keep inaccessible copies of your PII and non-PII subject to our data
                    retention policies.</p>
                <h4><strong>4. Cookies and Tracking</strong></h4>
                <p className="p_mrgin">We may use cookies and related technologies, such as web beacons, to collect
                    information on our website. A cookie is a text file that is placed on your hard disk by a web page
                    server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are
                    uniquely assigned to you and can only be read by a web server in the domain that issued the cookie
                    to you. One of the primary purposes of cookies is to provide a convenience feature to save you time.
                    The purpose of a cookie is to tell the Web server that you have returned to a specific page. For
                    example, if you register with us, a cookie helps Premiumtax.Credit to recall your specific information on
                    subsequent visits. This simplifies the process of recording your personal information, such as
                    billing addresses, shipping addresses, and so on. When you return to the same Premiumtax.Credit website, the
                    information you previously provided can be retrieved, so you can easily use the features that you
                    customized. A web beacon is a small graphic image that allows the party that set the web beacon to
                    monitor and collect certain information about the viewer of the web page, web-based document or
                    e-mail message, such as the type of browser requesting the web beacon, the IP address of the
                    computer that the web beacon is sent to and the time the web beacon was viewed. Web beacons can be
                    very small and invisible to the user, but, in general, any electronic image viewed as part of a web
                    page or e-mail, including HTML based content, can act as a web beacon. We may use web beacons to
                    count visitors to the web pages on the web site or to monitor how our users navigate the web site,
                    and we may include web beacons in e-mail messages in order to count how many messages sent were
                    actually opened, acted upon or forwarded.</p>
                <p className="p_mrgin">Premiumtax.Credit and/or its marketing partners/affiliates also may use cookies on our web site.
                    For instance, we may contract with our marketing partners/affiliates who will use cookies on our web
                    site to track and analyze anonymous usage and volume statistical information from our visitors and
                    members. Such information is shared externally only on an anonymous, aggregated basis. Marketing
                    partners/affiliates use persistent cookies to help us to improve the visitor experience, to manage
                    our site content, and to track visitor behavior. We may also contract with a third party to send
                    e-mail to our users.</p>
                <p className="p_mrgin">To help measure and improve the effectiveness of our e-mail communications, our
                    marketing partners/affiliates sets cookies. All data collected by our marketing partners/affiliates
                    on behalf of Premiumtax.Credit is used solely by or on behalf of Premiumtax.Credit and is shared externally only on an
                    anonymous, aggregated basis. From time to time, we may allow our marketing partners/affiliates to
                    post advertisements on our web site, and those our marketing partners/affiliates advertisements may
                    include a cookie or web beacon served by our marketing partners/affiliates. This Privacy Policy does
                    not cover the use of information collected from you by our marketing partners/affiliates ad servers.
                    We do not control cookies in such marketing partners/affiliates ads, and you should check the
                    privacy policies of those advertisers and/or ad services to learn about their use of cookies and
                    other technology before linking to an ad. We will not share your personal information with these
                    companies, but these companies may use information about your visits to this and other web sites in
                    order to provide advertisements on this site and other sites about goods and services that may be of
                    interest to you, and they may share your personal information that you provide to them with others.
                </p>
                <p className="p_mrgin">You have the ability to accept or decline cookies. Most Web browsers automatically
                    accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If
                    you choose to decline cookies, you may not be able to fully experience the interactive features of
                    the Premiumtax.Credit websites you visit.</p>
                <h4 className="mt-3"><strong>5. Third Party Access to Your Information</strong></h4>
                <p className="p_mrgin">Although you are entering into an Agreement with Premiumtax.Credit to disclose your information to
                    us, we do use third party individuals and organizations to assist us, including contractors, web
                    hosts, and others to allow you to access the Site.</p>
                <p className="p_mrgin">Throughout the course of our provision of our services to you, we may delegate our
                    authority to collect, access, use, and disseminate your information. It is therefore necessary that
                    you grant the third parties we may use in the course of our business the same rights that you afford
                    us under this Privacy Policy. For this reason, you hereby agree that for every authorization which
                    you grant to us in this Privacy Policy, you also grant to any third party that we may hire,
                    contract, or otherwise retain the services of for the purpose of operating, maintaining, repairing,
                    or otherwise improving or preserving our website or its underlying files or systems. You agree not
                    to hold us liable for the actions of any of these third parties, even if we would normally be held
                    vicariously liable for their actions, and that you must take legal action against them directly
                    should they commit any tort or other actionable wrong against you.</p>
                <p className="p_mrgin">For instance, in the ordinary course of business we may share some personal
                    information with companies that we hire to perform services or functions on our behalf. In all cases
                    in which we share your personal information with a third party for the purpose of providing a
                    service to us, we will not authorize them to keep, disclose or use your information with others
                    except for the purpose of providing the services we asked them to provide.</p>
                <p className="p_mrgin">We will not sell, exchange or publish your personal information, except in
                    conjunction with one of our marketing partners or affiliate(s). For some sorts of transactions, in
                    addition to our direct collection of information, our third-party service vendors (such as credit
                    card companies, clearinghouses and banks) who may provide such services as credit, insurance, and
                    escrow services may collect personal information directly from you to assist you with your
                    transaction. We do not control how these third parties use such information, but we do ask them to
                    disclose how they use your personal information before they collect it.</p>
                <p className="p_mrgin">If you submit a review for a third-party (person or business) using our Facebook Fan
                    Review Application, during the submission process we ask your permission to gather your basic
                    information (such as name and email address) which we then share with the third party for whom you
                    are submitting the review. We may be legally compelled to release your personal information in
                    response to a court order, subpoena, search warrant, law or regulation.</p>
                <p className="p_mrgin">We may cooperate with law enforcement authorities in investigating and prosecuting
                    web site visitors who violate our rules or engage in behavior, which is harmful to other visitors
                    (or illegal). We may disclose your personal information to third parties if we feel that the
                    disclosure is necessary to protect our rights or property, protect someone’s health, safety or
                    welfare, or to comply with a law or regulation, court order or other legal process. As discussed in
                    the section on cookies and other technologies, from time to time we may allow a third party to serve
                    advertisements on this web site.</p>
                <p className="p_mrgin">If you share information with the advertiser, including by clicking on their ads,
                    this Privacy Policy does not control the advertisers use of your personal information, and you
                    should check the privacy policies of those advertisers and/or ad services to learn about their use
                    of cookies and other technology before linking to an ad.</p>
                <h4 className="mt-3"><strong>6. Law Enforcement</strong></h4>
                <p>You agree that we may disclose your information to authorities if compelled to by a court order.
                    Additionally, you agree that we may disclose your information if we reasonably believe that you have
                    violated US laws, the terms of our Terms of Use or our Privacy Policy, or if we believe that a third
                    party is at risk of bodily or economic harm. In the event that we receive a subpoena affecting your
                    privacy, we may elect to notify you to give you an opportunity to file a motion to quash the
                    subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either. We may
                    also proactively report you and release your information without receiving any request to third
                    parties where we believe that it is proper to do so for legal reasons, where your actions violate
                    any US laws or any other country having jurisdiction over us, our Site, or our Terms of Use. You
                    release us from any damages that may arise from or relate to the release of your information to a
                    request from law enforcement agencies or private litigants. We may release your information under
                    the conditions listed in this paragraph whether it is to individuals or entities and to any state or
                    federal authorities, as required.</p>
                <h4><strong>7. Opt Out of Commercial, Non-Commercial Communications and Do Not Track</strong></h4>
                <p>If you decide to provide us with your contact information, you agree that we may send you
                    communications via text and emails. However, you may unsubscribe from certain communications by
                    notifying Premiumtax.Credit that you no longer wish to receive these communications and we will endeavor to
                    promptly remove you from our mailing list or communications once we have received that request. We
                    currently do not offer functionality for you to opt out through “do not track” listings. If you wish
                    to opt out of certain communications or information collection, please contact us at <a
                        href="">info@premiumtax.credit</a>.</p>
                <h4><strong>8. Third-Parties.</strong></h4>
                <p>Premiumtax.Credit or other users may post links to third party websites on Site, which may include information that
                    we have no control over. When accessing a third-party site through our Site, you acknowledge that
                    you are aware that these third party websites are not screened for privacy or security issues by us.
                </p>
                <p>Please be aware that this Privacy Policy, and any other policies in place, in addition to any
                    amendments, does not create rights enforceable by third parties. Premiumtax.Credit bears no responsibility for the
                    information collected or used by any advertiser or third party website. You must review their
                    electronic agreements and privacy policies to understand how their information collection practices
                    work.</p>
                <h4><strong>9. Security Measures</strong></h4>
                <p>We have implemented generally accepted standards of technology and operational security in order to
                    protect personally-identifiable information from loss, misuse, alteration, or destruction. Only
                    authorized personnel and third party vendors have access to your personal information, and these
                    employees and vendors are required to treat this information as confidential. Despite these
                    precautions, we cannot guarantee that unauthorized persons will not obtain access to your personal
                    information. For this reason, we recommend that you use anti-virus software, routine credit checks,
                    firewalls, and other precautions to protect yourself from security and privacy threats.</p>
                <h4><strong>10. Links</strong></h4>
                <p>This Site contains links to other sites that provide information that we consider to be interesting.
                    Premiumtax.Credit is not responsible for the privacy practices or the content of such web sites. </p>
                <h4><strong>11. Public Discussions</strong></h4>
                <p>This site may provide public discussions on various business valuation topics. Please note that any
                    information you post in these discussions will become public, so please do not post sensitive
                    information in the public discussions. Whenever you publicly disclose information online, that
                    information could be collected and used by others. We are not responsible for any action or policies
                    of any third parties who collect information that users disclose in any such forums on the web site.
                    Premiumtax.Credit does not agree or disagree with anything posted on the discussion board. Also remember that you
                    must comply with our other published policies regarding postings on our public forums.</p>
                <h4><strong>12. Your California Privacy Rights</strong></h4>
                <p>Premiumtax.Credit permits residents of the State of California to use its Site and complies with the California
                    Business and Professions Code §§ 22575-22579. If you are a California resident you may request
                    certain information regarding our disclosure of personal information to any third parties for their
                    direct marketing purposes. Various provisions throughout this Privacy Policy address requirements of
                    the Californian privacy statutes. Although we do not disseminate your information to third parties
                    without permission, you must presume that we collect electronic information from all visitors. You
                    may contact us at <a href="">info@premiumtax.credit</a> with any questions.</p>
                <h4><strong>13. Age Compliance</strong></h4>
                <p>We intend to fully comply with American and international laws respecting children’s privacy
                    including COPPA. Therefore, we do not collect or process any information for any persons under the
                    age of 18. If you are under 18 and using our Site, please stop immediately and do not submit any
                    information to us. In the event that you become aware that we have inadvertently collected any
                    information from users under the age of 18, please contact us immediately.</p>
                <h4><strong>14. International Transfer</strong></h4>
                <p>Your information may be transferred to - and maintained on - computers located outside of your state,
                    province, country, or other governmental jurisdiction where the privacy laws may not be as
                    protective as those in your jurisdiction. Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to that transfer. PII and Non-PII that are
                    submitted to Premiumtax.Credit will be collected, processed, stored, disclosed and disposed of in accordance with
                    applicable U.S. law and this policy. If you are a non-U.S. user, you acknowledge and agree that Premiumtax.Credit
                    may collect and use your Information and disclose it to other entities outside your resident
                    jurisdiction. In addition, such information may be stored on servers located outside your resident
                    jurisdiction. U.S. law may not provide the degree of protection for information that is available in
                    other countries.</p>
                <h4><strong>15. Merger and Acquisition</strong></h4>
                <p>In the event that Premiumtax.Credit is involved in a bankruptcy, merger, acquisition, reorganization or sale of
                    assets, your information may be sold or transferred as part of that transaction. Please be aware
                    that once the information is transferred your privacy rights may change.</p>
                <h4><strong>16. Amendments</strong></h4>
                <p>Like our Terms of Use, we may amend this Privacy Policy from time to time. When we amend this Privacy
                    Policy, we will modify the date listed on this Agreement or we may contact you. You must agree to
                    the amendments as a condition of your continued use of our Site. If you do not agree, you must
                    immediately cease using our Site and notify us of your refusal to agree by emailing us at <a
                        href="">info@premiumtax.credit</a>.</p>
                <h4><strong>17. Information</strong></h4>
                <p>If you have any questions or require additional information related to our information collection
                    practices, please contact us at <a href="">info@premiumtax.credit</a>.</p>
               
                <p className="mt-5"><b>Copyright 2023. All rights reserved.</b></p>
            </div>
        </div>
    </section>
</div>
        </>
    )
}
export default Privacy_policy