import React from 'react'
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <div>
<Helmet><script src="//b-js.ringba.com/CAfe8e30f0d9964386bac7acabadf4a10b" async></script></Helmet>
<header className="py-2 d-none">
{/* https://yousted.org/lfdesigns/Sass-Projects/ACA_benefit_quiz/v3/dist/img/logo.png */}
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <a href="">
            <img
              height={50}
              src=""
              alt=""
            />
            
          </a>
        </div>
        <div className="col-lg-6" />
      </div>
    </div>
  </header>
    </div>
  )
}

export default Header