import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import { getParameterByName } from "../../Utility/Helper";
import ReactPixel from "react-facebook-pixel";
import VisitorParamsContext from "../../Contexts/VisitorParams";

require("dotenv").config();
const FbPixel = () => {
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  let current_domain = visitorParameters.data.existingdomain;
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");
  const nextPage = query.get("next_page");


  if (queryString != null) {
    if (queryString.indexOf("&" + field + "=") !== -1) {
      const pid = getParameterByName(field, queryString);
      if (
        current_domain === visitorParameters.data.redirectDomain ||
        window.location.origin.includes("http://localhost")
      ) {

        const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        const query_url = window.location.href;
        const full_redirect_url = query_url.substring(query_url.indexOf('redirect_url=') + 13);
        ReactPixel.init(pid, advancedMatching, options);
        ReactPixel.pageView();
        ReactPixel.track("Lead");
        // history.push(
        //   "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
        // );
        window.location.href = full_redirect_url;
      } else {
        const app_url = window.location.origin;
        let redirect_url = '';
        redirect_url = app_url + "/" + nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid;
        const advRedirectDomain = "https://" + visitorParameters.data.redirectDomain;
        const fb_pixel_url = advRedirectDomain + "/fbpixel?" + queryString + "&redirect_url=" + redirect_url;
        window.location.href = fb_pixel_url;
      }
    }
  }
  return <></>;
};

export default FbPixel;